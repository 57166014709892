
import React from "react";
import { Link } from "react-router-dom";

const NewFooter = () => {
   
    return (

        <section className="mt-131 mb-40 ">
            <div className="innercontainer">
                <div className="footer-box">
                    <div className="footer-box-detail">
                        <div className=" text-center wrap-btn-footer wrap-btn">
                            {/* <button className="join-us btn btn-outlinenew btn-lg mt-35">
                  <span className="text-white">Join us</span>
                </button> */}
                            <h1 className="footer-heading mt-20">
                            Ready to experience how AI can simplify your workday?
                            </h1>
                            <p className="footer-para">Try Uniteam today and see how our AI-powered platform can help you work smarter, not harder.</p>
                            <Link to="/book-a-demo"  className=" btn-lg-footer btn  btn-lg  mt-20" >
                            Book a Demo
                            </Link>
                        </div>
                    </div>
                    <div>
                        <hr className="mt-35 hr-seprator" />
                        <nav className="navigation footer-box-detail">
                            <div className="nav-section">
                                <h4>Uniteam</h4>
                                <ul>
                                    {/* <li>Pricing</li>
                                    <li>Help Center</li>
                                    <li>About</li>
                                    <li>Contact</li> */}
                                    {/* all should be in new line */}
                                    {/* <Link to="/pricing" className="text-white">Pricing</Link><br /> */}
                                    {/* <Link to="/help-guide" className="text-white">Help Center</Link><br /> */}
                                    <Link to="/about-us" className="fitem">About Us</Link><br />
                                    <Link to="/contact-us" className="fitem">Contact</Link><br/>
                                    <Link to="/help-center" className="fitem">Support</Link><br/>
                                    <Link to="/documentation" className="fitem">Documentation</Link><br/>
                                    
                                </ul>
                            </div>
                            {/* <div className="nav-section">
                                <h4>Downloads</h4>
                                <ul>
                                    <li>Android</li>
                                    <li>iOS</li>
                                </ul>
                            </div>
                            <div className="nav-section">
                                <h4>Solutions</h4>
                                <ul>
                                    <li>AI Chat</li>
                                    <li>All Tasks</li>
                                </ul>
                            </div> */}
                            <div className="nav-section">
                                <h4>Email us</h4>
                                <ul>
                                    {/* <li>support@uniteam.io</li>
                                    <li>sales@uniteam.io</li> */}
                                    <p><span className="fachat"><a href="mailto:support@uniteam.ai" className="fitem">support@uniteam.ai</a>
                                    </span><br /><span className="fabriefcase"><a href="mailto:support@uniteam.ai" className="fitem">sales@uniteam.ai</a></span></p>
                                </ul>
                            </div>
                            <div className="nav-section nav-section-declearation ">
                                <ul>
                                    <li>2025 Uniteam. All rights reserved. A product of OpsEdge AI Pvt Ltd </li>
                                    {/* <li>Terms of service | Privacy policy</li> */}
                                    <Link to="/terms-of-use" className="fitem">Terms of Use</Link> | <a href="/privacy-policy.html" className="fitem">Privacy policy</a>
                                </ul>

                                <div className="menu-quick-links">
                      <span><a href="https://www.facebook.com/profile.php?id=61568779382803&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="/images/facebook.svg" width="34" /></a></span>
                      <span><a href="https://twitter.com/uniteamapp" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="/images/twitter.svg" width="34" /></a></span>
                      <span><a href="https://www.linkedin.com/company/uniteamai" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="/images/linkedin.svg" width="34" /></a></span>
                      <span><a href="https://www.instagram.com/uniteam.ai/" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="/images/instagram.svg" width="19"  className="mt-8px"/></a></span>
                    </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default NewFooter;